import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import HeroSection from '../HeroSection/HeroSection';
import Blogs1 from '../../assets/Blogs/blogs1.jpg';
import Blogs2 from '../../assets/Blogs/blogs2.jpg';
import Blogs3 from '../../assets/Blogs/blogs3.jpg';
import Testimonials from '../Testimonial/Testimonials';
import './Home.css';
import Modal from './Modal'

const Services = () => {
  const services = [
    {
      title: 'Property Sales',
      description: 'Explore a wide range of properties available for sale.',
      icon: 'fas fa-dollar-sign',
    },
    {
      title: 'Property Purchases',
      description: 'Find your dream property with our expert guidance.',
      icon: 'fas fa-signature',
    },
    {
      title: 'Consultation Services',
      description: 'Get professional advice for buying and selling properties.',
      icon: 'fas fa-comments',
    },
  ];

  return (
    <section className="services">
      <div className="container">
        <h2>Our Services</h2>
        <div className="services-container">
          {services.map((service, index) => (
            <div key={index} className="service">
              <i className={`service-icon ${service.icon}`}></i>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// About Us Section
const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="container">
        <h2>About Us</h2>
        <div className="about-content">
          <div className="about-text">
          <p>At Vaani Properties, we are passionate professionals dedicated to delivering exceptional real estate solutions. With expertise in property sales and purchases, our team is committed to helping you find the perfect property that meets your unique needs. Trust us to guide you every step of the way with integrity and personalized service.</p>
          </div>
          <div className="about-video">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/jDaA963n6Rg?si=GxGLaX6bIc-wvoAs&autoplay=1&loop=1&playlist=jDaA963n6Rg"
              title="About Us Video"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};


// Call to Action (CTA) Section
const CallToAction = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact'); // Change '/contact' to the actual path of your contact page
  };

  return (
    <section className="call-to-action">
      <div className="container">
        <h2>Ready to start your project?</h2>
        <button onClick={handleContactClick}>
          Contact Us
        </button>
      </div>
    </section>
  );
};

// Blog Preview Section
// Blog Section
const BlogPreview = () => {
  const blogPosts = [
    {
      title: "Navigating the Property Market in 2024",
      content:
        "Explore the key trends and forecasts for the property market in 2024. From market analysis to investment opportunities, learn how to make informed decisions in the evolving landscape of property sales and purchases.",
      image: Blogs1, // Ensure images are relevant to the content
    },
    {
      title: "Expert Tips for First-Time Home Buyers",
      content:
        "Buying your first home can be daunting. Get expert advice tailored to help first-time buyers navigate through the complexities of purchasing a home, ensuring a smooth and successful transaction.",
      image: Blogs2,
    },
    {
      title: "Maximizing ROI in Real Estate",
      content:
        "Discover strategies for maximizing your return on investment in real estate. This guide covers everything from property selection to market timing, designed for both seasoned investors and new entrants.",
      image: Blogs3,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const openModal = (post) => {
    setSelectedPost(post);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section className="blog-preview">
      <div className="container">
        <h2>Insights from Vaani Properties</h2>
        <div className="blog-container">
          {blogPosts.map((post, index) => (
            <div
              key={index}
              className="blog-post"
              onClick={() => openModal(post)}
            >
              <div className="image-card">
                <img src={post.image} alt={post.title} />
              </div>
              <div className="description-card">
                <h3>{post.title}</h3>
                <p>{post.content.substring(0, 120)}...</p> {/* Show an excerpt */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalOpen && <Modal post={selectedPost} closeModal={closeModal} />}
    </section>
  );
};

const Home = () => {
  return (
    <div>
      <HeroSection />
      <Services />
      <AboutUs />
      <CallToAction />
      <BlogPreview />
      <Testimonials />
    </div>
  );
};

export default Home;
