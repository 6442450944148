import React, { useState } from 'react';
import './Gallery.css';
import clientImage1 from '../../assets/pics/pic1.jpg';
import clientImage2 from '../../assets/pics/pic2.jpg';
import clientImage3 from '../../assets/pics/pic3.jpg';
import clientImage4 from '../../assets/pics/pic4.jpg';
import clientImage5 from '../../assets/pics/pic5.jpg';
import clientImage6 from '../../assets/pics/pic6.jpg';
import clientImage7 from '../../assets/pics/pic7.jpg';
import clientImage8 from '../../assets/pics/pic8.jpg';
import clientImage9 from '../../assets/pics/pic12.jpg';
import clientImage10 from '../../assets/pics/pic10.jpg';
import clientImage11 from '../../assets/pics/pic11.jpg';
import reviews from '../../assets/profile.jpg'



const testimonials = [
  {
    name: "Aman Verma",
    feedback: "Vaani Properties helped me find my dream home effortlessly. Their team was knowledgeable, patient, and attentive to my needs.",
    image: reviews,
  },
  {
    name: "Ritika Jain",
    feedback: "The best real estate experience I've had! Vaani Properties provided excellent service and guided me through the entire process with ease.",
    image: reviews,
  },
  {
    name: "Nikhil Mehta",
    feedback: "I was impressed by their professionalism and commitment. Vaani Properties made buying a commercial property a smooth experience.",
    image: reviews,
  },
  {
    feedback: "Their property management services are top-notch. I feel secure knowing my property is in good hands with Vaani Properties.",
    name: "Priya Singh",
    image: reviews,
  },
  {
    feedback: "Outstanding service and great communication throughout. I highly recommend Vaani Properties to anyone looking to buy or rent.",
    name: "Karan Sharma",
    image: reviews,
  },
];

const GallerySection = ({ title, images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((currentIndex + images.length - 1) % images.length);
  };

  return (
    <div className="gallery-section">
      <h2>{title}</h2>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-card" onClick={() => openModal(index)}>
            <img src={image.url} alt={image.alt} />
          </div>
        ))}
      </div>
      {modalOpen && (
        <div className="gallery-modal-backdrop">
          <div className="gallery-modal-content">
            <button onClick={closeModal} className="gallery-modal-close-btn">✖</button>
            <button onClick={prevImage} className="gallery-modal-nav-btn-prevbtn">〈</button>
            <img src={images[currentIndex].url} alt={images[currentIndex].alt} className="gallery-modal-image" />
            <button onClick={nextImage} className="gallery-modal-nav-btn-nextbtn">〉</button>
          </div>
        </div>
      )}
    </div>
  );
};

const GalleryHero = () => {
  const receptionImages = [
    { url: clientImage1, alt: 'Reception Image 1' },
    { url: clientImage2, alt: 'Reception Image 2' },
    { url: clientImage3, alt: 'Reception Image 3' },
    { url: clientImage4, alt: 'Reception Image 4' },
    { url: clientImage5, alt: 'Reception Image 5' },
    { url: clientImage6, alt: 'Reception Image 6' },
    { url: clientImage7, alt: 'Reception Image 7' },
    { url: clientImage8, alt: 'Reception Image 8' },
    { url: clientImage9, alt: 'Reception Image 9' },
    { url: clientImage10, alt: 'Reception Image 10' },
    { url: clientImage11, alt: 'Reception Image 11' },
  ];


  return (
    <>
      <div className="gallery-hero">
      </div>
      <GallerySection title="Our Work and Clients images" images={receptionImages} />

      <div className="testimonials-section">
<h2 className="testimonials-title">Testimonials</h2>
<div className="testimonials-grid">
  {testimonials.map((testimonial, index) => (
    <div className="testimonial-card" key={index}>
      <div className="testimonial-image" style={{ backgroundImage: `url(${testimonial.image})` }}></div>
      <div className="testimonial-content">
        <p className="testimonial-feedback">"{testimonial.feedback}"</p>
        <h3 className="testimonial-name">- {testimonial.name}</h3>
      </div>
    </div>
  ))}
</div>
</div>
    </>
  );
};

export default GalleryHero;