// Footer.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'; // Import social media icons as needed
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-grid">
            <div className="footer-section">
              <h3>Vaani Properties</h3>
              <p>Your trusted partner in real estate, offering premium residential and commercial properties. Our commitment to excellence and customer satisfaction ensures that you find the perfect space to call home or grow your business.</p>
            </div>
            <div className="footer-section">
              <h3>Services</h3>
              <ul>
                <li><Link to="/services">Property Rental</Link></li>
                <li><Link to="/services">Property Sale</Link></li>
                <li><Link to="/services">Property Management</Link></li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Navigation</h3>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Contact</h3>
              <p><a href="mailto:info@vaaniproperties.in">info@vaaniproperties.in</a></p>
              <p><a href="tel:+919540991011">+91 9540-991-011</a></p>
              <p>Plot No. 26, Pocket-9, Sector-25, Rohini, Delhi- 110085</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="social-icons">
            <a href="/" className='FB'><FaFacebook /></a>
            <a href="https://www.instagram.com/vaani_properties_rohini/?hl=en" className='IG'><FaInstagram /></a>
            <a href="https://www.youtube.com/@VaaniPropertiesExperts/videos" className='YT'><FaYoutube /></a>

          </div>
          <p>&copy; {new Date().getFullYear()} Vaani Properties | All rights reserved. Powered by <strong>BUSINESSKARO.COM</strong></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
