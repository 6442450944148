import React, { useState } from 'react';
import './ServicePage.css';
import residentialSales from '../../assets/Services/Residential Sale.webp';
import commercialSales from '../../assets/Services/Commercial Sales.webp';
import propertyManagement from '../../assets/Services/Properties Management.webp';
import investmentAdvisory from '../../assets/Services/Inventory Advisory.webp';
import legalAssistance from '../../assets/Services/Legal Assistance.webp';
import EnquiryForm from './Enquiry';

const ServicePage = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      id: 1,
      title: 'Residential Sales',
      description: 'Explore our range of residential properties for sale. From modern apartments to spacious villas, we help you find a home that suits your needs and aspirations.',
      image: residentialSales,
    },
    {
      id: 2,
      title: 'Commercial Sales',
      description: 'Unlock your business potential with our commercial properties. We offer a variety of office spaces and retail locations to support your business growth.',
      image: commercialSales,
    },
    {
      id: 3,
      title: 'Property Management',
      description: 'Our property management services take care of all aspects of your real estate investment, including tenant management, maintenance, and financial reporting.',
      image: propertyManagement,
    },
    {
      id: 4,
      title: 'Investment Advisory',
      description: 'Make informed decisions with our real estate investment advisory services. We provide expert insights to help you maximize your investment returns.',
      image: investmentAdvisory,
    },
    {
      id: 5,
      title: 'Legal Assistance',
      description: 'Navigate the complexities of property transactions with confidence. Our legal experts provide comprehensive support for contracts, disputes, and compliance.',
      image: legalAssistance,
    },
  ];

  const openForm = (service) => {
    setSelectedService(service);
  };

  const closeForm = () => {
    setSelectedService(null);
  };

  return (
    <div className="service-page">
      <h1 className="service-page-title">Our Services at Vaani Properties</h1>
      <div className="service-list">
        {services.map((service) => (
          <div key={service.id} className="service-item">
            <div className="service-item-image">
              <img src={service.image} alt={service.title} />
            </div>
            <div className="service-item-content">
              <h2 className="service-item-title">{service.title}</h2>
              <p className="service-item-description">{service.description}</p>
              <button
                className="service-item-button"
                onClick={() => openForm(service.title)}
              >
                Enquire Now
              </button>
            </div>
          </div>
        ))}
      </div>
      {selectedService && <EnquiryForm service={selectedService} closeForm={closeForm} />}
    </div>
  );
};

export default ServicePage;
