import React from 'react';
import CountUp from 'react-countup';
import './About.css';
import slideImage from '../../assets/aboutus.webp';
import storyImage from '../../assets/Services/Residential Sale.webp';
import missionIcon from '../../assets/missionIcon.png';
import visionIcon from '../../assets/vissionIcon.png';
import profile from '../../assets/profile.jpg'
import approach from '../../assets/Gallery/approach.webp'

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="header" style={{ backgroundImage: `url(${slideImage})` }}>
        <div className="overlay"></div>
      </div>
      <div className="content">
        <section className="story-section">
          <div className="story-image">
            <img src={storyImage} alt="Story" />
          </div>
          <div className="story-content">
            <h2>Our Story</h2>
            <p>
              Founded to redefine the real estate landscape, Vaani Properties is a name synonymous with trust and excellence. We specialize in the sale and purchase of residential and commercial properties, helping clients achieve their real estate goals with expertise and integrity.
            </p>
            <div className="sub-points">
              <div className="sub-point">
                <img src={missionIcon} alt="Mission Icon" />
                <div>
                  <h3>Our Mission</h3>
                  <p>To empower clients with comprehensive real estate services that maximize value and satisfaction.</p>
                </div>
              </div>
              <div className="sub-point">
                <img src={visionIcon} alt="Vision Icon" />
                <div>
                  <h3>Our Vision</h3>
                  <p>To be the foremost choice for property solutions, transforming the industry with innovative approaches.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="statistics">
          <div className="stat">
            <CountUp start={0} end={200} duration={3} />+
            <h3>Satisfied Clients</h3>
          </div>
          <div className="stat">
            <CountUp start={0} end={400} duration={3} />+
            <h3>Properties Sold</h3>
          </div>
          <div className="stat">
            <CountUp start={0} end={15} duration={3} />+
            <h3>Years of Experience</h3>
          </div>
          <div className="stat">
            <CountUp start={0} end={60} duration={3} />+
            <h3>Industry Experts</h3>
          </div>
        </section>
        <section className="team">
          <h2>Meet the Team</h2>
          <div className="team-members">
            <div className="team-member">
              <img src={profile} alt="Team Member" />
              <h3>Karan Sharma</h3>
              <p>Role: Managing Director</p>
              <p>Background: With over 20 years in the real estate sector, John leads our team with unparalleled expertise and vision.</p>
            </div>
            <div className="team-member">
              <img src={profile} alt="Team Member" />
              <h3>Luvkush Namdev</h3>
              <p>Role: Head of Sales</p>
              <p>Background: Jane's strategic approach and market insights have been instrumental in driving our sales success.</p>
            </div>
          </div>
        </section>
        <section className="approach-section">
          <div className="approach-content">
            <h2>Our Approach</h2>
            <blockquote>"Your property journey is our priority, and success is achieved through innovation and commitment."</blockquote>
            <div className="highlight">
              <p>We prioritize client needs and tailor our services accordingly.</p>
              <p>We embrace cutting-edge solutions for enhanced outcomes.</p>
              <p>We are dedicated to achieving excellence in every transaction.</p>
            </div>
            <p>
              At Vaani Properties, our approach centers on personalized service, ensuring every client receives the attention and expertise they deserve. We strive to create value through dedicated service and innovative solutions, transforming the real estate experience for our clients.
            </p>
          </div>
          <div className="approach-image">
            <img src={approach} alt="Approach" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutPage;